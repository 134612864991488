
import { ModelConnection, ModelConnectionDirection, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../../helpers/analytics'
import { ModelModule } from '../../store'

interface Item {
  active: boolean
  click: () => void
  icon: string
  id: string
  subtitle: string
  title: string
}

@Component({
  name: 'ModelConnectionDirection'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly connection!: ModelConnection
  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get editable () {
    return this.permission !== 'read'
  }

  get items (): Item[] {
    return [
      {
        active: this.connection.direction === 'outgoing',
        click: () => this.updateDirection('outgoing'),
        icon: 'fas-right-long',
        id: 'outgoing',
        subtitle: 'Request and response',
        title: 'Outgoing'
      },
      {
        active: this.connection.direction === 'bidirectional',
        click: () => this.updateDirection('bidirectional'),
        icon: 'fas-left-right',
        id: 'bidirectional',
        subtitle: 'Event based or WebSocket',
        title: 'Bidirectional'
      },
      {
        active: this.connection.direction === null,
        click: () => this.updateDirection(null),
        icon: 'fas-horizontal-rule',
        id: 'nodirection',
        subtitle: 'Dependency with no communication',
        title: 'No direction'
      }
    ]
  }

  get currentItem () {
    return this.items.find(o => o.active)
  }

  updateDirection (direction: ModelConnectionDirection) {
    if (this.connection.direction === direction) {
      return
    }

    const prevConnection = window.structuredClone(this.connection)

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (this.currentDiagram?.status === 'draft') {
      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: [{
              id: this.connection.id,
              props: {
                direction: this.connection.direction
              },
              type: 'model-connection-update'
            }]
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: [{
            id: this.connection.id,
            props: {
              direction
            },
            type: 'model-connection-update'
          }]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.connection.versionId
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      revertTasks.push({
        id: this.connection.id,
        props: {
          direction: this.connection.direction
        },
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(this.connection.id, {
        direction
      })
      this.modelModule.setConnectionVersion(connection)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.connectionUpdate({
          connectionId: connection.id,
          landscapeId: this.currentLandscape.id,
          props: connectionUpdate,
          versionId: this.connection.versionId
        })
      })

      tasks.push({
        id: connection.id,
        props: connectionUpdate,
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      analytics.modelConnectionUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelConnectionDescriptionLength: prevConnection.description?.length || 0,
        modelConnectionDirection: prevConnection.direction,
        modelConnectionNameLength: prevConnection.name.length,
        modelConnectionStatus: prevConnection.status,
        modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
        modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
        modelConnectionUpdateDirection: connectionUpdate.direction,
        organizationId: [this.currentLandscape.organizationId]
      })
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })
  }
}
